<template>
    <leave-request-loader v-if="loading"></leave-request-loader>
    <div v-if="!loading" class="home-container">
        <navbar title="Leave Request"></navbar>
        <div class="body-page" style="margin-top: 60px">
            <div class="body-section">
                <div class="attendance-report">
                    <span class="report-title">August</span>
                    <router-link :to="{name: 'live'}" class="report-nav">View</router-link>
                    <div class="report-box">
                        <span class="count">{{ summary.approved }}</span>
                        <span class="desc">Approved</span>
                    </div>
                    <div class="report-box">
                        <span class="count">{{ summary.rejected }}</span>
                        <span class="desc">Rejected</span>
                    </div>
                    <div class="report-box">
                        <span class="count">{{ summary.pending }}</span>
                        <span class="desc">Pending</span>
                    </div>
                    <div class="report-box">
                        <span class="count">{{ summary.total }}</span>
                        <span class="desc">Total</span>
                    </div>
                </div>

                <router-link :to="{name: 'add-leave'}" class="leave-button">
                    <lord-icon
                        src="https://cdn.lordicon.com/qtqvorle.json"
                        trigger="loop"
                        colors="outline:#00bcd4,primary:#00bcd4,secondary:#ffffff,tertiary:#ff725e"
                        stroke="90"
                        style="width:40px;height:40px">
                    </lord-icon>
                    Create Leave Request
                </router-link>

                <div class="leave-item">
                    <div class="leave-card" v-for="lv in leave" :key="lv.id">
                        <span class="leave-title">{{ lv.tipe }}</span>
                        <span class="leave-date">{{ convertDate(lv.start_date) }}</span>
                        <span class="leave-period">{{ convertDate(lv.start_date) }} - {{ convertDate(lv.end_date) }}</span>
                        <div :class="{'leave-status-success' : lv.status === 'Approved',
                        'leave-status-fail' : lv.status === 'Rejected',
                        'leave-status-pending' : lv.status === 'Pending'}">
                            {{ lv.status }}
                        </div>
                        <!-- <a class="leave-detail">View</a> -->
                    </div>
                    <div style="width: 100%;height: 100px;"></div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import LeaveRequestLoader from '@/components/Loader/LeaveRequestLoader.vue';
import axios from 'axios'

export default {
    name: 'LeaveRequestView',
    components: {
        Navbar, LeaveRequestLoader,
    },
    data(){
        return{
            loading: true,
            leave: [],
            summary: {},
        }
    },
    created(){
        this.getSummary()
        this.getLeave()
    },
    methods: {
        async getLeave(){
            try {
                const { data } = await axios.get('/leave', {
                    headers: {
                        Authorization: this.$store.getters.GET_AUTH_TOKEN
                    }
                })

                this.leave = data.data
                this.loading = false
            } catch(e){
                console.log(e)
                if(e.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                } else if(e.response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                } 
            }
        },
        async getSummary(){
            try {
                const { data } = await axios.get('/leave-summary', {
                    headers: {
                        Authorization: this.$store.getters.GET_AUTH_TOKEN
                    }
                })

                this.summary = data.data
            } catch(error){
                console.log(error)
            }
        },
        convertDate(date){
            const newDate = new Date(date)
            const day = newDate.getDate() <= 9 ? '0' + String(newDate.getDate()) : String(newDate.getDate())
            const month = (newDate.getMonth() + 1) <= 9 ? '0' + String(newDate.getMonth()) : String(newDate.getMonth() + 1)
            return day + '-' + month + '-' + newDate.getFullYear()
        }
    }
}
</script>

<style>
.leave-button{
    position: absolute;
    top: 145px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 94%;
    height: 40px;
    border-radius: 10px;
    border: 1.5px solid var(--bluecolor);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 13pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--bluecolor);
    cursor: pointer;
}

.leave-item{
    position: absolute;
    top: 200px;
    left: 40%;
    -webkit-transform: translateX(-40%);
    transform: translateX(-40%);
    width: 90%;
    padding: 0;
}

@media only screen and (max-width: 767px){
    .leave-item{
        position: absolute;
        top: 200px;
        left: 40%;
        -webkit-transform: translateX(-40%);
        transform: translateX(-40%);
        width: 88%;
        padding: 0;
        margin-left: -10px;
    }   
}

.leave-item .leave-card{
    position: relative;
    width: 100%;
    height: 70px;
    border:  1px solid var(--border);
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 15px;
    margin-top: 10px;
}

.leave-item .leave-card .leave-title{
    font-size: 12pt;
    font-weight: bold;
    color: var(--bluecolor);
}

.leave-item .leave-card .leave-date{
    margin-top: 15px;
    font-size: 12pt;
    font-weight: bold;
    color: #474747;
}

.leave-item .leave-card .leave-period{
    margin-top: 3px;
    font-size: 10pt;
    color: #e0e0e0;
    font-weight: 400;
}

.leave-item .leave-card .leave-status-success{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #00e28f;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-item .leave-card .leave-status-fail{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #b50000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-item .leave-card .leave-status-pending{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #ffed2b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-item .leave-card .leave-detail{
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #838383;
    font-size: 10pt;
}
</style>