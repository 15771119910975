<template>
  <navbar title="Paycheck January"></navbar>

  <div
    style="
      position: relative;
      width: 90%;
      height: 300px;
      padding: 4%;
      border: 1px solid gray;
      margin: 0 auto;
      margin-top: 70px;
    "
  >
    <div style="position: absolute;left: 10px;">
        <img
        src="https://merp.apotikmelawai.id/img/sti.17930ce0.png"
        style="position: relative;object-fit: cover;"
        class="paycheck-logo"
        alt="logo sti"
        />

        <p :style="{'font-size': company + 'px'}"
        style="position: relative;margin-top: 10px;left: 1%">
            PT. SARI TIRTA INDONESIA <br>
            <span :style="{'font-size': address + 'px'}">
            Jl. KS Tubun 31, Petamburan
            </span>
        </p>
    </div>

    <span
      style="position: absolute; top: 10px; right: 5%;font-weight: bold;color: #454545"
      :style="{ 'font-size': title + 'px' }"
      >SLIP GAJI KARYAWAN</span
    >
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  name: "SalaryDetailView",
  components: {
    Navbar,
  },
  data() {
    return {
      title: 0,
      company: 0,
      address: 0,
    };
  },
  mounted() {
    this.getScreen();
  },
  methods: {
    getScreen() {
      if (window.screen.width <= 500) {
        this.title = window.screen.width / 20;
        this.company = window.screen.width / 34;
        this.address = window.screen.width / 40;
      } else {
        this.title = window.screen.width / 50;
         this.company = window.screen.width / 64;
        this.address = window.screen.width / 74;
      }
    },
  },
};
</script>

<style>
.paycheck-logo{
    top: 10px;
    width: 300px;
    height: 100px;
}

@media(max-width: 500px){
    .paycheck-logo{
        top: 0px;
        width: 100px;
        height: 50px;
    }   
}
</style>
