<template>
    <navbar title="Leave Request"></navbar>
    <PDFViewer
      :source="source"
      style="
        position: relative;
        height: 100svh; 
        width: 90svw;
        top: 50px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        margin-left: 1.5%;"
    :controls="control" />
    <div style="
        width: 100%;
        height: 60px;
        margin-bottom: 10px;">
    </div>
</template>

<script>
import PDFViewer from 'pdf-viewer-vue'
import Navbar from '@/components/Navbar.vue';

export default {
    name: 'LeavePDFView',
    components: {
        PDFViewer,
        Navbar
    },
    data() {
        return {
            source: this.uri + '/api/v1/view-leave/Leave-1155-21-12-2023.pdf',
            control: [
                'print',
                'rotate',
                'zoom',
                'catalog',
                'switchPage',
            ]
        }
    },
    created(){
        this.getPDF(this.$route.params.name)
    },
    methods: {
        async getPDF(filename){
            try {
                await fetch(this.uri + '/api/v1/view-leave/' + filename, {
                    method: 'GET',
                    headers: { 
                        'Authorization' : this.$store.getters.GET_AUTH_TOKEN
                    }
                }).then((res) => {
                    return res.blob()
                }).then((blob) => {
                    let url = URL.createObjectURL(blob)
                    this.source = url
                })
            } catch(error){
                console.log(error)
            }
        },
    }
}
</script>