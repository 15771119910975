import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ForgotPasswordView from '@/views/ForgotPasswordView.vue'
import LiveAttendanceView from '@/views/LiveAttendanceView.vue'
import VisitAttendanceView from '@/views/VisitAttendanceView.vue'
import RequestAttendanceView from '@/views/RequestAttendanceView.vue'
import InboxView from '@/views/InboxView.vue'
import SettingView from '@/views/SettingView.vue'
import ProfileView from '@/views/ProfileView.vue'
import AttendanceView from '@/views/AttendanceView.vue'
import AttendanceDetailView from '@/views/AttendanceDetailView.vue'
import ChangePasswordView from '@/views/ChangePasswordView.vue'
import LeaveRequestView from '@/views/LeaveRequestView.vue'
import NewLeaveRequestView from '@/views/NewLeaveRequestView.vue'
import ApproveLeaveView from '@/views/ApproveLeaveView.vue'
import Attendancev2View from '@/views/Attendancev2View.vue'
import AttendanceAllView from '@/views/AttendanceAllView.vue'
import ResetView from '@/views/ResetView.vue'
import LeavePDFView from '@/views/LeavePDFView.vue'
import SalaryView from '@/views/SalaryView.vue'
import SalaryDetailView from '@/views/SalaryDetailView.vue'

const routes = [
  {path: '/', name: 'home', component: HomeView, meta: {login: true}},
  {path: '/login', name: 'login', component: LoginView, meta: {guest: true}},
  {path: '/live', name: 'live', component: LiveAttendanceView, meta: {login: true}},
  {path: '/visit', name: 'visit', component: VisitAttendanceView, meta: {login: true}},
  {path: '/request', name: 'request', component: RequestAttendanceView, meta: {login: true}},
  {path: '/inbox', name: 'inbox', component: InboxView, meta: {login: true}},
  {path: '/setting', name: 'setting', component: SettingView, meta: {login: true}},
  {path: '/profile', name: 'profile', component: ProfileView, meta: {login: true}},
  {path: '/clockin', name: 'clockin', component: AttendanceView, meta: {login: true}},
  {path: '/clockout', name: 'clockout', component: AttendanceView, meta: {login: true}},
  {path: '/detail/:id', name: 'detail', component: AttendanceDetailView, meta: {login: true}},
  {path: '/password', name: 'password', component: ChangePasswordView, meta: {login: true}},
  {path: '/forgot', name: 'forgot', component: ForgotPasswordView, meta: {guest: true}},
  {path: '/reset/:token', name: 'reset', component: ResetView},
  {path: '/leave', name: 'leave', component: LeaveRequestView, meta: {login: true}},
  {path: '/add-leave', name: 'add-leave', component: NewLeaveRequestView, meta: {login: true}},
  {path: '/approve/:ibxid/:id', name: 'approve', component: ApproveLeaveView, meta: {login: true}},
  {path: '/attendance', name: 'attendance', component: Attendancev2View},
  {path: '/attendance/list/:date', name: 'attendance-list', component: AttendanceAllView, meta: {login: true}},
  {path: '/leave-view/:name', name: 'leave-view', component: LeavePDFView, meta: {login: true}},
  {path: '/salary', name: 'salary', component: SalaryView, meta: {login: true} },
  {path: '/salary-detail/:id', name: 'salary-detail', component: SalaryDetailView }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(document.getElementById("map")){
    document.getElementById("map").remove()
  }

  window.scrollTo(0, 0);
  if(to.matched.some(record => record.meta.login)){
    if (!store.getters.GET_AUTH_TOKEN){
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)){
    if (store.getters.GET_AUTH_TOKEN) {
      next({
        name: 'home'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
