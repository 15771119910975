<template>
    <!-- <inbox-loader v-show="loading"></inbox-loader> -->
    <navbar></navbar>

    <div style="display: flex;flex-direction: column;gap: 10px;width: 100%;margin-top: 80px;">
        <div class="paycheck">
            <span class="leave-title">Paycheck</span>
            <span class="leave-employee">John Doe</span>
            <span class="leave-date">{{ new Date().toDateString() }}, {{ new Date().toLocaleTimeString() }}</span>
            <span class="leave-period">Period, January 2024</span>
            <!-- <div :class="{'leave-status-success': inbx.status === 'Success',
            'leave-status-success': inbx.status === 'Approved',
            'leave-status-pending': inbx.status === 'Pending',
            'leave-status-fail': inbx.status === 'Rejected'}"
            style="text-align: center;width: 80px;">
                {{ inbx.status }}
            </div> -->

            <a href="" class="leave-detail">
                View
            </a>   
        </div>
    </div>

    <div class="mb-50"></div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
    name: 'SalaryView',
    components: {
        Navbar
    }
}
</script>

<style>
.message-container{
    width: 100svw;
}

.paycheck{
    position: relative;
    width: 90%;
    height: 100px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 10px 3%;
}

.paycheck .leave-title{
    font-size: 14pt;
    font-weight: bold;
    color: var(--bluecolor);
}

.paycheck .leave-employee{
    margin-top: 5px;
    font-size: 12pt;
    font-weight: 400;
    color: #474747;
}

.paycheck .leave-date{
    margin-top: 3px;
    font-size: 12pt;
    font-weight: bold;
    color: #474747;
}

.paycheck .leave-period{
    margin-top: 0px;
    font-size: 10pt;
    color: #e0e0e0;
    font-weight: 400;
}

.paycheck .leave-status-success{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 120px;
    height: 25px;
    border-radius: 10px;
    background: #00e28f;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.paycheck .leave-status-fail{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #b50000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.paycheck .leave-status-pending{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #ffed2b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.paycheck .leave-detail{
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #838383;
    font-size: 10pt;
}
</style>