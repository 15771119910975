<template>
  <new-leave-loader v-if="loading"></new-leave-loader>
  <div v-if="!loading">
    <navbar title="Leave Request"></navbar>
    <div class="create-request">
      <div class="request-card">
        <span class="request-title">Create New Leave Request</span>
        <label>Leave Type</label>
        <select 
            type="text" 
            name="leave-type"
            v-model="leave.tipe"
        >
          <option value="Sakit">Sakit</option>
          <option value="Alpa">Alpa</option>
          <option value="Lain-lain">Lain-lain</option>
        </select>
        <span class="text-danger">{{ errorType }}</span>

        <label>Leave Reason</label>
        <textarea 
            rows="5" 
            placeholder="Leave Reason"
            v-model="leave.reason">
        </textarea>
        <span class="text-danger">{{ errorReason }}</span>

        <label>Leave Duration</label>
        <select 
            type="text" 
            name="period" 
            v-model="duration">
          <option value="Full Day">Full Day</option>
        </select>
        <span class="text-danger">{{ errorDuration }}</span>

        <div
          v-if="duration === 'Full Day'"
          style="display: flex; 
          flex-direction: row; 
          flex-wrap: wrap; gap: 20px"
        >
          <div style="width: 45%">
            <label>Start Date</label>
            <input
              class="card-input"
              type="date"
              name="startdate"
              placeholder="Start Date"
              v-model="leave.start_date"
            />
            <span class="text-danger">{{ errorStart }}</span>
          </div>
          <div style="width: 45%">
            <label>End Date</label>
            <input
              class="card-input"
              type="date"
              name="startdate"
              placeholder="End Date"
              v-model="leave.end_date"
            />
            <span class="text-danger">{{ errorEnd }}</span>
          </div>
        </div>

        <div
          v-if="duration === 'Half Day'"
          style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 20px"
        >
          <div style="width: 45%">
            <label>Start Time</label>
            <input
              class="card-input"
              type="time"
              name="startdate"
              placeholder="Start Date"
            />
          </div>
          <div style="width: 45%">
            <label>End Time</label>
            <input
              class="card-input"
              type="time"
              name="startdate"
              placeholder="End Date"
            />
          </div>
        </div>

        <label>Attachment File</label>
        <input
          class="card-input"
          type="file"
          name="attachment"
          accept="application/pdf"
          @change="handleUpload"
        />
        <span class="text-danger">{{ errorAttachment }}</span>

        <label>Employee Signature</label>

        <div class="flex-row">
          <div class="source">
            <vue-drawing-canvas
              ref="VueCanvasDrawing"
              v-model:image="image"
              :width="swidth"
              :height="300"
              :stroke-type="strokeType"
              :line-cap="lineCap"
              :line-join="lineJoin"
              :fill-shape="fillShape"
              :eraser="eraser"
              :lineWidth="line"
              :color="color"
              :background-color="backgroundColor"
              :background-image="backgroundImage"
              :watermark="watermark"
              :initial-image="initialImage"
              saveAs="png"
              :styles="{
                border: 'solid 1px #000',
              }"
              :lock="disabled"
              @mousemove="getCoordinate($event)"
              @mousedown="setMovement"
              @mouseup="stopMovement"
              :additional-images="additionalImages"
            />

            <span class="text-danger">{{ errorSignature }}</span>

            <div class="button-container">
              <button
                type="button"
                class="draw-btn"
                @click.prevent="disabled = !disabled"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-lock"
                  viewBox="0 0 16 16"
                >
                  <path
                    v-if="!disabled"
                    d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"
                  />
                  <path
                    v-else
                    d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"
                  />
                </svg>
                <span v-if="!disabled">Save</span>
                <span v-else>Edit</span>
              </button>
              <button
                type="button"
                class="draw-btn"
                @click.prevent="$refs.VueCanvasDrawing.undo()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-counterclockwise"
                  viewBox="0 0 16 16">
                  <path
                    fill-rule="evenodd"
                    d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                  />
                  <path
                    d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
                  />
                </svg>
                Undo
              </button>
              <button
                type="button"
                class="draw-btn"
                @click.prevent="$refs.VueCanvasDrawing.redo()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path
                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
                  />
                </svg>
                Redo
              </button>
              <button
                type="button"
                class="draw-btn"
                @click.prevent="resetDraw">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-file-earmark"
                  viewBox="0 0 16 16">
                  <path
                    d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
                  />
                </svg>
                Reset
              </button>
            </div>
          </div>

          <div class="output" hidden>
            <p>Output:</p>
            <img :src="image" id="signature" style="border: solid 1px #000000" />
          </div>
        </div>

        <button class="request-submit" @click="submitLeaveRequest">
          <span v-if="!isSubmiting" style="margin-top: 15px">
              Submit Request
          </span>

          <div v-if="isSubmiting" class="spinner center">
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
              <div class="spinner-blade"></div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueDrawingCanvas from "vue-drawing-canvas";
import Navbar from "@/components/Navbar.vue";
import NewLeaveLoader from "@/components/Loader/NewLeaveLoader.vue";
import axios from "axios";

export default {
  name: "LeaveRequestView",
  components: {
    Navbar,
    NewLeaveLoader,
    VueDrawingCanvas,
  },
  data() {
    return {
      showModal: false,
      duration: "Full Day",
      loading: true,
      initialImage: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "#000000",
          width: 5,
          fill: false,
        },
      ],
      x: 0,
      y: 0,
      image: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 4,
      color: "#000000",
      strokeType: "dash",
      lineCap: "round",
      lineJoin: "round",
      backgroundColor: "#FFFFFF",
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
      leave: {
        tipe: null,
        nik: null,
        reason: null,
        duration: null,
        start_date: null,
        end_date: null,
        attachment: null,
        signature: null,
      },
      errorType: null,
      errorNik: null,
      errorReason: null,
      errorDuration: null,
      errorStart: null,
      errorEnd: null,
      errorAttachment: null,
      errorSignature: null,
      isMove: false,
      move_cnt: 0,
      move: 0,
      swidth: 0,
      isSubmiting: false,
    };
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 2000)
  },
  mounted() {
    this.swidth = window.screen.width * 85 / 100
    this.leave.nik = this.$store.getters.GET_AUTH_INFO.nik
    if ("vue-drawing-canvas" in window.localStorage) {
      this.initialImage = JSON.parse(
        window.localStorage.getItem("vue-drawing-canvas")
      )
    }
  },
  methods: {
    resetDraw(){
      this.$refs.VueCanvasDrawing.reset()
      this.move_cnt = 0
    },
    async setImage(event) {
      let URL = window.URL;
      this.backgroundImage = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawing.redraw();
    },
    async setWatermarkImage(event) {
      let URL = window.URL;
      this.watermark = {
        type: "Image",
        source: URL.createObjectURL(event.target.files[0]),
        x: 0,
        y: 0,
        imageStyle: {
          width: 600,
          height: 400,
        },
      };

      await this.$refs.VueCanvasDrawing.redraw();
    },
    getCoordinate(event) {
      let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
      this.x = coordinates.x;
      this.y = coordinates.y;

      if(this.isMove){
        this.move += 1
      }
    },
    setMovement(){
      this.isMove = true
      this.move_cnt += 1
    },
    stopMovement(){
      this.isMove = false
      this.move_cnt = this.move_cnt + this.move
    },
    getStrokes() {
      window.localStorage.setItem(
        "vue-drawing-canvas",
        JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
      );
      alert(
        "Signatures saved, reload your browser to see the canvas with previously saved image"
      );
    },
    removeSavedStrokes() {
      window.localStorage.removeItem("vue-drawing-canvas");
      alert("Signatures cleared from local storage");
    },
    async submitLeaveRequest(){
        try {
            if(this.isSubmiting) return;
            this.isSubmiting = true;

            this.leave.duration = this.duration
            let cnt = 0;
            if(!this.leave.tipe) {
              this.errorType = 'Leave type is required'
              cnt += 1
            }

            if(!this.leave.reason) {
              this.errorReason = 'Leave reason is required'
              cnt += 1
            }

            if(!this.leave.duration){
              this.errorDuration = 'Leave duration is required'
              cnt += 1
            }

            if(!this.leave.start_date){
              this.errorStart = 'Start Date is required'
              cnt += 1
            }

            if(!this.leave.end_date) {
              this.errorEnd = 'End Date is required'
              cnt += 1
            }

            if(!this.leave.attachment){
              this.errorAttachment = 'Attachment file is required'
              cnt += 1
            }

            if(cnt > 0){
              setTimeout(() => {
                this.errorType = null
                this.errorNik = null
                this.errorReason = null
                this.errorDuration = null
                this.errorStart = null
                this.errorEnd = null
                this.errorAttachment = null
              }, 5000);
              return;
            }

            const img = document.getElementById('signature')
            const name = this.$store.getters.GET_AUTH_INFO.name
            const day = new Date().getDate() <= 9 ? '0' + String(new Date().getDate()) : String(new Date().getDate())
            const month = (new Date().getMonth() + 1) <= 9 ? '0' + String(new Date().getMonth()) : String(new Date().getMonth() + 1)
            const date = day + month + String(new Date().getFullYear());
            const filename = 'Signature-' + name + '-' + date + '-' + String(new Date().getTime()) + '.png';

            await fetch(img.src)
            .then(res => res.blob())
            .then(blob => {
              this.leave.signature = new File([blob], filename, blob)
            })

            const formData = new FormData()
            formData.append('tipe', this.leave.tipe)
            formData.append('nik', this.leave.nik)
            formData.append('reason', this.leave.reason)
            formData.append('leave_duration', this.leave.duration)
            formData.append('start_date', this.leave.start_date)
            formData.append('end_date', this.leave.end_date)
            formData.append('files', this.leave.attachment)
            formData.append('signature', this.leave.signature)

            await axios.post('/leave', formData, {
              headers: {
                Authorization: this.$store.getters.GET_AUTH_TOKEN
              }
            })
            
            this.$router.push({name: 'leave'})
            this.isSubmiting = false;
        } catch(error){
            console.log(error)
            this.isSubmiting = false;
        }
    },
    handleUpload(e){
        let maximumSize = 1 * 1024 * 1024;
        let images = null;
        
        if(e.target.files[0]) images = e.target.files[0];
        if(e.target.files[0] && e.target.files[0].size > maximumSize){
            this.$emit('message', "Attachment size too large, maximum 1 Mb!");
            this.$emit('response', false)
            this.$emit('clicked', false)
            this.$emit('alert', true)
            return;
        }

        this.filename = images.name;
        const lastDot = this.filename.lastIndexOf('.');
        const ext = this.filename.substring(lastDot + 1);            

        let blob = images.slice(0, images.size, images.type); 
        const newFile = new File([blob], 'Attachment-' + String(this.$store.getters.GET_AUTH_INFO.name).replace(" ","_") + '-' + String(Math.floor(Math.random() * 1839763215)) + String(Date.now()) + '.' + ext, {
            type: images.type
        });

        this.leave.attachment = newFile;
    }
  },
};
</script>

<style>
.create-request {
  position: relative;
  top: 10px;
  left: 0;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-request .request-card {
  position: relative;
  width: 90%;
  height: auto;
  margin: 0 auto;
  margin-top: 60px;
  border: 1px solid var(--border);
  background: var(--white);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.create-request .request-card label {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
  font-size: 10pt;
}

.create-request .request-card .request-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: var(--bluecolor);
}

.create-request .request-card select {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--border);
  background: var(--white);
  font-size: 11pt;
  padding: 10px;
}

.create-request .request-card textarea {
  width: 94%;
  border-radius: 10px;
  border: 1px solid var(--border);
  background: var(--white);
  font-size: 11pt;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.create-request .request-card .card-input {
  width: 94%;
  height: 25px;
  border-radius: 10px;
  border: 1px solid var(--border);
  background: var(--white);
  font-size: 11pt;
  padding: 10px;
}

.create-request .request-card .request-submit {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  background: var(--bluecolor);
  color: var(--white);
  border: none;
  font-size: 12pt;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-content: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.button-container > * {
  margin-top: 15px;
  margin-right: 10px;
}

.button-container .draw-btn {
  width: 25%;
  height: 40px;
  background: var(--backcolor);
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
