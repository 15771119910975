<template>
    <home-loader v-if="loading"></home-loader>
    <congrats v-if="!loading"></congrats>

    <div v-if="!loading" class="home-container">
        <div class="top-page">
            <div class="profile-section">
                <div class="profile-img">
                    <img class="img-thumb" id="profileImg" src="" alt="">
                </div>
                <div class="profile-name">
                    <span class="name">{{ userName }}</span>
                    <span class="section">OFFICE</span>
                </div>
                <div class="profile-setting">
                    <router-link :to="{name: 'setting'}"><i class="fa-solid fa-gear"></i></router-link>
                </div>
            </div>
            <div class="attendance-section">
                <router-link :to="{name: 'live'}" id="live" class="attendance-button">
                    <lord-icon
                        src="https://cdn.lordicon.com/fihkmkwt.json"
                        trigger="loop"
                        colors="primary:#545454,secondary:#ff725e"
                        style="width:40px;height:40px">
                    </lord-icon>
                    <span>Live Attendance</span>
                </router-link>
                <router-link :to="{name: 'leave'}" class="attendance-button">
                    <lord-icon
                        src="https://cdn.lordicon.com/pqxdilfs.json"
                        trigger="hover"
                        colors="outline:#ff725e,primary:#ff725e,secondary:#ff725e,tertiary:#ffffff"
                        style="width:40px;height:40px">
                    </lord-icon>
                    <span>Request Leave</span>
                </router-link>
                <router-link :to="{name: 'inbox'}" class="attendance-button">
                    <lord-icon
                        src="https://cdn.lordicon.com/gzmgulpl.json"
                        trigger="loop"
                        colors="outline:#545454,primary:#ff725e,secondary:#e4e4e4"
                        style="width:50px;height:50px">
                    </lord-icon>
                    <span>Inbox</span> 
                </router-link>
                <button type="button" @click="showRequestModal = true" class="attendance-button">
                    <lord-icon
                        src="https://cdn.lordicon.com/kjkiqtxg.json"
                        trigger="hover"
                        colors="outline:#545454,primary:#ff725e,secondary:#00bcd4,tertiary:#e4e4e4"
                        style="width:45px;height:45px">
                    </lord-icon>
                    <span>Request</span>
                </button>
            </div>
        </div>
        <div class="body-page" style="margin-top: -40px;">
            <div class="attendance-report">
                <span class="report-title">{{ month }}</span>
                <router-link :to="{name: 'live'}" class="report-nav">View All</router-link>
                <div class="report-box">
                    <span class="count">{{ summary.attend }}</span>
                    <span class="desc">Present</span>
                </div>
                <div class="report-box">
                    <span class="count">{{ summary.late }}</span>
                    <span class="desc">Late</span>
                </div>
                <div class="report-box">
                    <span class="count">0</span>
                    <span class="desc">Leave</span>
                </div>
                <div class="report-box">
                    <span class="count">{{ summary.total }}</span>
                    <span class="desc">Total</span>
                </div>
            </div>
            <div class="body-section" style="margin-top: 115px;">
                <div class="title">
                    <h3>Announcement</h3>
                </div>
                <div class="content">
                    <span class="empty-data">Belum ada data</span>
                </div>
            </div>
        </div>
    </div>

    <request v-if="showRequestModal" 
            @clicked="onClosedRequest"
            @response="onResponse"
            @message="onMessage"
            @alert="onAlert"
            @redirect="typeUrl">
    </request>

    <alert v-if="showAlert" 
            :message="message" 
            :status="status" 
            :type="types"
            @clicked="onClosedAlert">
    </alert>
</template>

<script>
import HomeLoader from '@/components/Loader/HomeLoader.vue'
import Request from '@/components/Request.vue'
import Congrats from '@/components/Congrats.vue'
import Alert from  '@/components/Alert.vue'
import axios from 'axios'

export default {
  name: 'HomeView',
  components: {
    Congrats,
    HomeLoader,
    Alert,
    Request,
  },
  data(){
    return {
            showRequestModal: false,
            showAlert: false,
            message: null,
            status: false,
            types: null,
            profile: [],
            loading: true,
            basecolor: '',
            summary: null,
            month: 0,
        }
    },
    computed: {
        userName(){
            return this.$store.getters.GET_AUTH_INFO.name;
        }
    },
    created(){
        this.basecolor = localStorage.getItem('basecolor');
        this.getSummary();
        this.getPeriode();
    },
    methods: {
        async getImage(filename){
          await fetch(this.uri + '/images/profile/' + filename, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Authorization' : this.$store.getters.GET_AUTH_TOKEN,
                'AccessToken': this.$store.getters.GET_AUTH_INFO.access_token
            }
          }).then((res) => {
                return res.blob()
          }).then((blob) => {
                let url = URL.createObjectURL(blob)
                document.getElementById('profileImg').src = url
          })
        },
        async getSummary(){
            try {
                const {data} = await axios.get('/summary_attendance', { 
                    headers: {
                        'Authorization': this.$store.getters.GET_AUTH_TOKEN,
                        'AccessToken': this.$store.getters.GET_AUTH_INFO.access_token
                    }
                })
                        
                this.getImage(this.$store.getters.GET_AUTH_INFO.photo);
                this.summary = data.data
                this.loading = false
            } catch(e){
                if(e.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                } else if(e.response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                } 
            }
        },
        getPeriode(){
            const currentDate = new Date(new Date().toLocaleString('en', {
                hour12: false,
                timeZone: 'Asia/Jakarta',
            }))
            
            switch (currentDate.getMonth()) {
            case 0:
                this.month = "January";
                break;
            case 1:
                this.month = "February";
                break;
            case 2:
                this.month = "March";
                break;
            case 3:
                this.month = "April";
                break;
            case 4:
                this.month = "May";
                break;
            case 5:
                this.month = "June";
                break;
            case 6:
                this.month = "July";
                break;
            case 7:
                this.month = "August";
                break;
            case 8: 
                this.month = "September"
                break;
            case 9:
                this.month = "October"
                break;
            case 10:
                this.month = "November"
                break;
            case 11:
                this.month = "December"
            }
        },
        onClosedAlert(value){this.showAlert = value},
        onClosedRequest(value){this.showRequestModal = value},
        onResponse(value){this.status = value},
        onMessage(value){this.message = value},
        onAlert(value){this.showAlert = value},
        typeUrl(value){this.types = value},
    }
}
</script>
