<template>
    <leave-approval-loader v-if="loading"></leave-approval-loader>
    <div v-if="!loading">
        <navbar title="Leave Request"></navbar>
        <div class="create-request">
            <div class="request-card">
                    <span class="request-title">Leave Request Approval</span>
                    
                    <div style="position: relative;width: 95%;max-width: 1100px;height: 300px;border: 1px solid var(--border);display: flex;flex-direction: column;font-size: 9pt;padding: 5px">
                        <span>FORMULIR IZIN / SAKIT</span>
                        <span>PT. SARI TIRTA INDONESIA</span>

                        <div style="position:relative;margin-top: 20px">Name  
                            <div style="position: absolute;left: 90px;top: 0;display: flex;flex-direction: row;gap: 1px">
                                <span style="margin-right: 5px;">:</span> 
                                <span v-for="i in dot" :key="i">{{ i }}</span>
                            </div>
                        </div>
                        <span style="position: absolute;top: 55px;left: 110px;color: #515151;">{{ user.name }}</span>

                        <div style="position:relative;margin-top: 7px">NIK  
                            <div style="position: absolute;left: 90px;top: 0;display: flex;flex-direction: row;gap: 1px">
                                <span style="margin-right: 5px;">:</span> 
                                <span v-for="i in dot" :key="i">{{ i }}</span>
                            </div>
                        </div>
                        <span style="position: absolute;top: 79px;left: 110px;color: #515151;">{{ user.nik }}</span>
                        
                        <div style="position:relative;margin-top: 7px">Department  
                            <div style="position: absolute;left: 90px;top: 0;display: flex;flex-direction: row;gap: 1px">
                                <span style="margin-right: 5px;">:</span> 
                                <span v-for="i in dot" :key="i">{{ i }}</span>
                            </div>
                        </div>
                        <span style="position: absolute;top: 105px;left: 110px;color: #515151;">{{ user.lok_kd }}</span>
                        
                        <div style="position:relative;margin-top: 7px">Absent Date  
                            <div style="position: absolute;left: 90px;top: 0;display: flex;flex-direction: row;gap: 1px">
                                <span style="margin-right: 5px;">:</span> 
                                <span v-for="i in dot" :key="i">{{ i }}</span>
                            </div>
                        </div>
                        <span style="position: absolute;top: 130px;left: 115px;color: #515151;">{{ convertDate(leave.start_date) }}</span>
                        
                        <div style="position:relative;margin-top: 7px;z-index: 2">Leave Type  
                            <div style="position: absolute;left: 90px;top: 0;display: flex;flex-direction: row;gap: 1px">
                                <span style="margin-right: 5px;">:</span> 
                                <span v-for="i in dot" :key="i">{{ i }}</span>
                            </div>
                        </div>
                        <span style="position: absolute;top: 155px;left: 110px;color: #515151;z-index: 2">{{ leave.tipe }}</span>

                        <div style="position:relative;margin-top: 7px;z-index: 2">Leave Reason  
                            <div style="position: absolute;left: 90px;top: 0;display: flex;flex-direction: row;gap: 1px">
                                <span style="margin-right: 5px;">:</span> 
                                <span v-for="i in dot" :key="i">{{ i }}</span>
                            </div>
                        </div>
                        <span style="position: absolute;top: 180px;left: 110px;color: #515151;z-index: 2">{{ leave.reason }}</span>

                        <span style="position: absolute;bottom: 85px;left: 10px;color: #515151;z-index: 2;">Jakarta,   {{ convertDate(new Date()) }}</span>
                        <img style="position: absolute;bottom: 25px;left: 5px;width: 80px;" :src="signaturespv">
                        <span style="position: absolute;bottom: 80px;left: 10px;z-index: 2">........, ...................</span>
                        <div style="position: absolute;bottom: -100px;left:-120px;border: none;">
                            <div style="transform: scale(20%);border: none;"
                                v-if="leave.status === 'Pending'">
                                <img :src="image" id="spv-signature" />
                            </div>
                        </div>
                        <span style="position: absolute;bottom: 25px;left: 10px;color: #515151;">{{ karyawan.name }}</span>
                        <span style="position: absolute;bottom: 20px;left: 10px">.............................</span>
                        <span style="position: absolute;bottom: 5px;left: 10px">Project Manager</span>

                        <span style="position: absolute;bottom: 80px;right: 10px;z-index: 2">Employee Name,</span>
                        <img style="position: absolute;bottom: 10px;right:10px;width: 90px;" :src="signatureuser">
                        <span style="position: absolute;bottom: 25px;right: 10px;color: #515151;">{{ user.name }}</span>
                        <span style="position: absolute;bottom: 20px;right: 10px">.............................</span>
                    </div>

                    <button class="download-button" 
                        @click="downloadAttachment(leave.attachment)">
                        <div class="download-icon">
                            <i class="fa-solid fa-download"></i>
                        </div>
                        <a style="position: absolute;
                                  width: 100%;
                                  display: flex;
                                  flex-wrap: nowrap;
                                  margin-left: 15%;
                                  text-decoration: none;
                                  color: #009cd5">
                           {{ String(leave.attachment).substring(0, Math.floor(swidth / 8)) }}
                        </a>
                    </button>

                    <div style="width: 100%;font-size: 12pt;">
                        <b>Keterangan</b><span class="text-danger">*</span><br>

                        <div style="
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: start;
                            align-items: start;">
                            <div class="description-input">
                                <input 
                                    type="radio" 
                                    name="keterangan" 
                                    id="cuti"
                                    style="width: 18px;height: 18px;"
                                    value="Potong Cuti"
                                    v-model="description">
                                <label for="sakit" style="margin-top: 3px;font-size: 12pt;">Potong Cuti</label>
                            </div>
                            <div class="description-input">
                                <input 
                                    type="radio" 
                                    name="keterangan" 
                                    id="alpa"
                                    style="width: 18px;height: 18px;"
                                    value="Alpa"
                                    v-model="description">
                                <label for="sakit" style="margin-top: 3px;font-size: 12pt;">Alpa</label>
                            </div>
                            <div class="description-input">
                                <input 
                                    type="radio" 
                                    name="keterangan" 
                                    id="tidak cuti"
                                    style="width: 18px;height: 18px;"
                                    value="Tidak Potong Cuti"
                                    v-model="description">
                                <label for="sakit" style="margin-top: 3px;font-size: 12pt;">Tidak dipotong cuti</label>
                            </div>
                            <div class="description-input">
                                <input 
                                    type="radio" 
                                    name="keterangan" 
                                    id="libur pengganti"
                                    style="width: 18px;height: 18px;"
                                    value="Libur Pengganti"
                                    v-model="description">
                                <label for="sakit" style="margin-top: 3px;font-size: 12pt;">Libur Pengganti dari :</label>
                            </div>
                        </div>

                        <input v-if="description === 'Libur Pengganti'"
                            style="width: 180px;height: 40px;border-radius: 10px;"
                            type="date">
                    </div>
                    <span class="text-danger">{{ errorDesc }}</span>

                    <label v-if="leave.status === 'Pending'" style="font-size: 12pt;">
                        <b>Supervisor Signature</b>
                    </label>
                    
                    <div class="flex-row" v-if="leave.status === 'Pending'">
                        <div class="source">
                            <vue-drawing-canvas
                            ref="VueCanvasDrawing"
                            v-model:image="image"
                            :width="swidth"
                            :height="300"
                            :stroke-type="strokeType"
                            :line-cap="lineCap"
                            :line-join="lineJoin"
                            :fill-shape="fillShape"
                            :eraser="eraser"
                            :lineWidth="line"
                            :color="color"
                            :background-color="backgroundColor"
                            :background-image="backgroundImage"
                            :watermark="watermark"
                            :initial-image="initialImage"
                            saveAs="png"
                            :styles="{
                                border: 'solid 1px #000',
                            }"
                            :lock="disabled"
                            @mousemove="getCoordinate($event)"
                            @mousedown="setMovement"
                            @mouseup="stopMovement"
                            :additional-images="additionalImages"
                            />
                            <span class="text-danger">{{ errorSignature }}</span>
                            
                            <div class="button-container">
                                <button type="button" class="draw-btn" @click.prevent="disabled = !disabled">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
                                        <path v-if="!disabled" d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                        <path v-else d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                                    </svg>
                                    <span v-if="!disabled">Save</span>
                                    <span v-else>Edit</span>
                                </button>
                                <button type="button" class="draw-btn" @click.prevent="$refs.VueCanvasDrawing.undo()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                                        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                                    </svg>
                                    Undo
                                </button>
                                <button type="button" class="draw-btn" @click.prevent="$refs.VueCanvasDrawing.redo()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                    </svg>
                                    Redo
                                </button>
                                <button type="button" class="draw-btn" @click.prevent="$refs.VueCanvasDrawing.reset()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                    </svg>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <button class="request-submit" 
                        v-if="leave.status === 'Pending'"
                        @click="approveLeaveRequest">
                        <span v-if="!isApprove" 
                        style="margin-top: 15px">
                            Approve
                        </span>

                        <div v-if="isApprove" class="spinner center">
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                        </div>
                    </button>

                    <button class="request-reject" 
                        v-if="leave.status === 'Pending'"
                        @click="rejectLeaveRequest">
                        <span v-if="!isReject">
                            Reject
                        </span>

                        <div v-if="isReject" class="spinner center">
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                        </div>
                    </button>
            </div>
        </div>
    </div>
</template>

<script>
import VueDrawingCanvas from 'vue-drawing-canvas'
import Navbar from '@/components/Navbar.vue';
import LeaveApprovalLoader from '@/components/Loader/LeaveApprovalLoader.vue';
import axios from 'axios';

export default {
    name: 'ApproveLeaveRequest',
    components: {
        Navbar, 
        LeaveApprovalLoader,
        VueDrawingCanvas,
    },
    data(){
        return{
            showModal: false,
            duration: 'Full Day',
            loading: true,
            initialImage: [
                {
                type: "dash",
                from: {
                    x: 262,
                    y: 154,
                },
                coordinates: [],
                color: "#000000",
                width: 5,
                fill: false,
                },
            ],
            x: 0,
            y: 0,
            image: "",
            eraser: false,
            disabled: false,
            fillShape: false,
            line: 4,
            color: "#000000",
            strokeType: "dash",
            lineCap: "round",
            lineJoin: "round",
            backgroundColor: "#FFFFFF",
            backgroundImage: null,
            watermark: null,
            additionalImages: [],
            swidth: 0,
            leave: {},
            karyawan: {},
            user: {},
            signatureuser: null,
            signaturespv: null,
            errorSignature: null,
            isMove: false,
            move: 0,
            move_cnt: 0,
            dot: [],
            isApprove: false,
            isReject: false,
            role: null,
            description: null,
            errorDesc: null,
            is_proccess: false,
        }
    },
    created(){
        this.role = this.$store.getters.GET_AUTH_INFO.role
        if(this.role !== 2){
            this.$router.push('/leave')
        }

        this.createDots()
        this.swidth = window.innerWidth * 84 / 100
        setTimeout(() => {this.loading = false}, 2000)        
    },
    mounted(){
        this.getLeaveDetail();
        if ("vue-drawing-canvas" in window.localStorage) {
            this.initialImage = JSON.parse(
                window.localStorage.getItem("vue-drawing-canvas")
            );
        }
    },
    methods: {
        createDots(){
             const dots = window.innerWidth / 6.5
             for(let i = 0; i < dots; i++){
                this.dot.push('.');
             }
        },
        async getLeaveDetail(){
            try {
                const leaveid = this.$route.params.id
                const { data } = await axios.get('/leave-detail', {
                    headers: {
                        Authorization: this.$store.getters.GET_AUTH_TOKEN,
                        LeaveID: leaveid
                    }
                })

                this.leave = data.data.leave
                this.karyawan = data.data.karyawan
                this.user = data.data.user
                this.getSignatureUser(this.leave.signature)
                if(this.leave.status === 'Approved'){
                    this.getSignatureSpv(this.leave.spv_signature)
                }
            } catch(error){                
                if(error.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                } else if(error.response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                }
            }
        },
        async setImage(event) {
            let URL = window.URL;
            this.backgroundImage = URL.createObjectURL(event.target.files[0]);
            await this.$refs.VueCanvasDrawing.redraw();
        },
        async setWatermarkImage(event) {
            let URL = window.URL;
            this.watermark = {
                type: "Image",
                source: URL.createObjectURL(event.target.files[0]),
                x: 0,
                y: 0,
                imageStyle: {
                width: 600,
                height: 400,
                },
            };

            await this.$refs.VueCanvasDrawing.redraw();
        },
        getCoordinate(event) {
            let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
            this.x = coordinates.x;
            this.y = coordinates.y;

            if(this.isMove){
                this.move += 1
            }
        },
        setMovement(){
            this.isMove = true
            this.move_cnt += 1
        },
        stopMovement(){
            this.isMove = false
            this.move_cnt = this.move_cnt + this.move
        },
        getStrokes() {
            window.localStorage.setItem(
                "vue-drawing-canvas",
                JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
            );
            alert(
                "Signatures saved, reload your browser to see the canvas with previously saved image"
            );
        },
        removeSavedStrokes() {
            window.localStorage.removeItem("vue-drawing-canvas");
            alert("Signatures cleared from local storage");
        },
        convertDate(date){
            const newDate = new Date(date)
            const day = newDate.getDate() <= 9 ? '0' + String(newDate.getDate()) : String(newDate.getDate())
            const month = (newDate.getMonth() + 1) <= 9 ? '0' + String(newDate.getMonth()) : String(newDate.getMonth() + 1)
            return day + '-' + month + '-' + newDate.getFullYear()
        },
        async getSignatureUser(filename){
          await fetch(this.uri + '/images/signature/' + filename, {
            method: 'GET',
            headers: { 
                'Authorization' : this.$store.getters.GET_AUTH_TOKEN
            }
          }).then((res) => {
                return res.blob()
          }).then((blob) => {
                let url = URL.createObjectURL(blob)
                this.signatureuser = url                
          })
        },
        async getSignatureSpv(filename){
          await fetch(this.uri + '/images/signature/' + filename, {
            method: 'GET',
            headers: { 
                'Authorization' : this.$store.getters.GET_AUTH_TOKEN
            }
          }).then((res) => {
                return res.blob()
          }).then((blob) => {
                let url = URL.createObjectURL(blob)
                this.signaturespv = url                
          })
        },
        async downloadAttachment(filename) {
            try {
                console.log('clicked');
                const server = this.uri + '/files/attachment/' + filename;
                const response = await fetch(server, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                        Authorization: this.$store.getters.GET_AUTH_TOKEN
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to download PDF file. Status: ${response.status}`);
                }

                const blob = await response.blob();
                const downloadLink = document.createElement('a');
                const objectURL = URL.createObjectURL(blob);

                downloadLink.href = objectURL;
                downloadLink.download = `Attachment-${new Date().toLocaleDateString()}.pdf`;

                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(objectURL);
            } catch (error) {
                console.error(error);
                alert(`Failed to download PDF file. Error: ${error.message}`);
            }
        },
        async approveLeaveRequest(){
            try {
                if(this.isApprove) return;
                this.isApprove = true;

                if(!this.description){
                    this.errorDesc = 'Select description first!'
                    return;
                }

                const img = document.getElementById('spv-signature')
                const name = this.$store.getters.GET_AUTH_INFO.name
                const day = new Date().getDate() <= 9 ? '0' + String(new Date().getDate()) : String(new Date().getDate())
                const month = (new Date().getMonth() + 1) <= 9 ? '0' + String(new Date().getMonth()) : String(new Date().getMonth() + 1)
                const date = day + month + String(new Date().getFullYear());
                const filename = 'Signature-' + name + '-' + date + '-' + String(new Date().getTime()) + '.png';

                await fetch(img.src)
                .then(res => res.blob())
                .then(blob => {
                    this.signaturespv = new File([blob], filename, blob)
                })

                const formData = new FormData()
                formData.append('leave_id', this.leave.id)
                formData.append('signature', this.signaturespv)
                formData.append('description', this.description)

                const {data} = await axios.put('/leave', formData, {
                    headers: { Authorization: this.$store.getters.GET_AUTH_TOKEN }
                })

                console.log(data)
                this.isApprove = false;
                window.location.href = "/inbox"
            } catch(error){
                this.isApprove = false;
                console.log(error)
            }
        },
        async rejectLeaveRequest(){
            try {
                if(this.isReject) return;
                this.isReject = true;

                const { data } = await axios.delete('/leave', {
                    headers: { Authorization: this.$store.getters.GET_AUTH_TOKEN },
                    data: { 
                        leaveid: this.$route.params.id,
                        ibxid: this.$route.params.ibxid,
                    }
                })

                console.log(data)
                this.isApprove = false;
                window.location.href = "/inbox"
            } catch(error){
                this.isReject = false;
                console.log(error)
            }
        }
    },
}
</script>

<style>
.create-request{
    position: relative;
    top: 10px;
    left: 0;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-request .request-card{
    position: relative;
    width: 90%;
    height: auto;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.create-request .request-card label{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    font-size: 10pt;

}

.create-request .request-card .request-title{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: var(--bluecolor);
}

.create-request .request-card select{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    font-size: 11pt;
    padding: 10px;
}

.create-request .request-card textarea{
    width: 94%;
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    font-size: 11pt;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.create-request .request-card .card-input{
    width: 94%;
    height: 25px;
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    font-size: 11pt;padding: 10px
}

.create-request .request-card .request-submit{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    background: var(--bluecolor);
    color: var(--white);
    border: none;
    font-size: 12pt;
    font-family: Arial, Helvetica, sans-serif;
}

.create-request .request-card .request-reject{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 5px;
    background: var(--melawai);
    color: var(--white);
    border: none;
    font-size: 12pt;
    font-family: Arial, Helvetica, sans-serif;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px
}

.button-container > * {
    margin-top: 15px;
    margin-right: 10px;
}

.button-container .draw-btn{
    width: 25%;
    height: 40px;
    background: var(--backcolor);
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
}

.download-button{
    position: relative;
    width: 98%;
    height: 50px;
    border: 1px solid var(--bluecolor);
    display: flex;
    flex-direction: row;
    color: #fff;
    overflow-x: hidden;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.download-button .download-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background: var(--bluecolor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17pt;
}

.description-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 30px;
    gap: 10px;
}
</style>