<template>
    <forgot-loader v-show="loading"></forgot-loader>
    <div v-show="!loading" class="page-wrapper">
        <img class="login-bg" id="login-bg" :src="selectedImage" alt="">
        <div class="login-form">
            <p class="login-form-title">Forgot Password </p>
            
            <label>
                <input required="" placeholder="" type="email" class="input" v-model="email" id="email">
                <span id="label-email">Email</span>
                <div class="error-message">{{ errorEmail }}</div>
            </label> 

            <button class="login-form-submit" @click="reset">Reset</button>
            <router-link :to="{name: 'login'}" class="back-btn">
                <i class="fa-solid fa-angles-left"></i> <span>Back</span>
            </router-link>

            <span class="login-bottom-text">
              <span class="copyright">©</span> 
                  ESS MELAWAI GROUP
              <span class="version">v1.0</span>
            </span>
        </div>
    </div>

    <alert v-if="showAlert" 
          :message="message" 
          :status="status" 
          :type="types"
          @clicked="onClosedAlert">
    </alert>
</template>

<script>
import ForgotLoader from '@/components/Loader/ForgotLoader.vue';
import Alert from '@/components/Alert.vue';
import axios from 'axios';

export default {
    name: 'ForgotPasswordView',
    components: {
      ForgotLoader,
      Alert,
    },
    data(){
      return {
          showAlert: false,
          message: null,
          status: null,
          types: null,
          email: null,
          password: '',
          errorNik: null,
          errorPass: null,
          loading: true,
          images: [
            '/assets/images/apotik.png',
            '/assets/images/tamani.png',
            '/assets/images/sti.png',
            '/assets/images/movenpick.png',
            '/assets/images/rmk.png',
            '/assets/images/expresso.png',
            '/assets/images/visicare.png',
            '/assets/images/iris.png',
          ],
          selectedImage: null,
      }
    },
    watch: {
        selectedImage: function() {
          var root = document.documentElement;
          switch (this.selectedImage) {
            case "/assets/images/tamani.png":
                root.style.setProperty("--logincolor", "#33601d");
                root.style.setProperty("--loginhover", "#172e0c");
                break;
            case "/assets/images/apotik.png":
                root.style.setProperty("--logincolor", "#b22a2e");
                root.style.setProperty("--loginhover", "#7a181b");
                break;
            case "/assets/images/sti.png":
                root.style.setProperty("--logincolor", "#700921");
                root.style.setProperty("--loginhover", "#470212");
                break;
            case "/assets/images/movenpick.png":
                root.style.setProperty("--logincolor", "#9f8c50");
                root.style.setProperty("--loginhover", "#69592a");  
                break;
            case "/assets/images/rmk.png":
                root.style.setProperty("--logincolor", "#732c3d");
                root.style.setProperty("--loginhover", "#541827");
                document.getElementById("login-bg").style.marginTop = "3svh"
                break;
            case "/assets/images/expresso.png":
                root.style.setProperty("--logincolor", "#474747");
                root.style.setProperty("--loginhover", "#292828");
                document.getElementById("login-bg").style.marginTop = "-5svh"
                break;
            case "/assets/images/visicare.png":
                root.style.setProperty("--logincolor", "#00bfe0");
                root.style.setProperty("--loginhover", "#048ca4");
                document.getElementById("login-bg").style.marginTop = "-9svh"
                break;
            case "/assets/images/iris.png":
                root.style.setProperty("--logincolor", "#0172c0");
                root.style.setProperty("--loginhover", "#024370");
                document.getElementById("login-bg").style.marginTop = "-10svh"
                document.getElementById("login-bg").style.width = "50%"
                document.getElementById("login-bg").style.maxWidth = "220px"
                break;
            }
        },
        email: function() {
            this.checkFocus('email', this.email);
        },
    },
    created() {
      setTimeout(() => {this.loading = false}, 1000);
      this.selectedImage = this.randomItem(this.images)
    },
    methods: {
      onClosedAlert(value){this.showAlert = value},
      reset(){
        const data = {
          email: this.email
        }

        axios.post('/forgot_password', data)
        .then((res) => {
          this.message = res.data.message
          this.status = true
          this.types = 'login'
          this.showAlert = true
        })
        .catch((err) => {
          this.message = err.response.data.message
          this.status = false
          this.showAlert = true
        })
      },
      checkFocus(id, data){
        const div = document.getElementById(id);
        if(data !== '') {
          div.focus();
          const label = document.getElementById("label-" + id);
          label.style.fontFamily = 'Arial, Helvetica, sans-serif';
          label.style.top = '30px';
          label.style.fontSize = '8pt';
          label.style.fontWeight = '600';
        } else { 
          div.blur();
          const label = document.getElementById("label-" + id);
          label.style.fontFamily = 'Arial, Helvetica, sans-serif';
          label.style.top = '15px';
          label.style.fontSize = '11pt';
          label.style.fontWeight = '400';
        }
      },
      randomItem (items) {
        return items[Math.floor(Math.random()*items.length)];
      },
      login(){
        
        if(!this.nik) this.errorNik = "Field NIK harus diisi!";
        if(!this.password) this.errorPass = "Field password harus diisi";

        if(this.nik && this.password){
          localStorage.setItem('nik', this.nik);
          localStorage.setItem('password', this.password);
          if(localStorage.getItem('nik') && localStorage.getItem('password')){
            this.$router.push({name: 'home'});
          }
        }

        setTimeout(() => {
            this.errorNik = null;
            this.errorPass = null;
        }, 5000);
      }
    },
}
</script>

<style>

</style>